/* src/components/PaymentForm.css */
.payment-form-card {
    max-width: 400px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the card */
  }
  
  .card-body {
    padding: 15px; /* Adjust padding if needed */
  }
  
  .form-col {
    display: flex;
    flex-direction: column;
  }
  
  .form-control {
    margin-bottom: 10px; /* Adjust spacing between fields */
  }
  
  .btn {
    width: 100%; /* Make button span the full width */
  }
  
  .hidden {
    display: none; /* Hide the date field */
  }
  