.amount-text {
  color: #28a745; /* Green color for positive amounts */
  font-weight: bold;
  display: inline-block;
  transition: transform 0.5s, opacity 0.5s;
}

.tooltip-text {
  font-size: 14px;
  color: #333;
}

.amount-text.show {
  transform: translateY(-10px); /* Move up slightly when shown */
  opacity: 1;
}

.amount-text.hide {
  opacity: 0;
}
