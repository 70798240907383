/* src/App.css */
:root {
  --primary-color: #0066cc; /* Replace with your primary color */
  --secondary-color: #00cc99; /* Replace with your secondary color */
  --background-color: #f4f4f4; /* Replace with your background color */
  --card-shadow: rgba(0, 0, 0, 0.1); /* Shadow color */
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.card {
  border-radius: 15px; /* Rounded corners for floating effect */
  box-shadow: 0 8px 16px var(--card-shadow); /* Floating shadow */
  margin: 20px;
  transition: box-shadow 0.3s ease-in-out; /* Smooth shadow transition */
}

.card:hover {
  box-shadow: 0 12px 24px var(--card-shadow); /* Enhanced shadow on hover */
}

.card-header {
  background-color: var(--primary-color);
  color: #fff;
}

.card-body {
  padding: 1.5rem;
}

.btn-primary {
  background-color: var(--primary-color);
  border: none;
}

.btn-primary:hover {
  background-color: darken(var(--primary-color), 10%);
}

.alert-success {
  background-color: var(--secondary-color);
  color: #fff;
}

.alert-danger {
  background-color: #ff4d4d; /* Red for errors */
  color: #fff;
}
