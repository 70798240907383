.card-header {
  background-color: #007bff; /* Blue color */
  color: white;
  padding: 0.75rem 1.25rem;
}

.header-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}

.small-btn {
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  min-width: 50px;
  text-align: center;
  background-color: #28a745; /* Green color */
  border: none;
  color: white;
}
